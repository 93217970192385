import React from "react";

import Layout from "../components/common/layout/layout";
// import Brands from "../views/shared/Brands/Brands";
import Reviews from "../components/Reviews/Reviews";
import SEO from "../components/common/SEO/SEO";
import InfoRemedy from "../views/shop/remedies/info/InfoRemedy";
import InfoRemedyRescue from "../views/shop/remedies/info/InfoRemedyRescue";
import FAQ from "../views/shop/FAQ/FAQ";
import greenCircle from "../assets/vectors/green-circle-big.svg";
import {Link} from "@reach/router";
import {StaticImage} from "gatsby-plugin-image";

const ShopPage = () => {
  return (
    <Layout>
      <SEO
        title="Preparados florales y de rescate"
        description="Tú te conoces mejor que nadie; así que diseña tu propia fórmula floral, con las Flores de Bach que tú elijas, y recibe tu preparado floral personalizado en 24/48h.
Nunca antes fue tan fácil y rápido conseguir tu preparado floral personalizado.
 "
        canonical={"tienda"}
      />
      <section className={"o-hero container o-hero--shop"}>
        <div className={"m-hero-content"}>
            <h1 className={"h2 text-left a-title-shop"}>
              Échale flores de Bach a la vida.
            </h1>
            <p className={"a-desc text-left"}>
              Tú te conoces mejor que nadie; así que diseña tu propia fórmula floral con las Flores de Bach que tú elijas.
            </p>
        </div>
          <div className={"m-hero-shop__image"}>
            <StaticImage
              src={"../assets/images/remedio.png"}
              alt={"Comprar ahora flores de Bach"}
              className={""}
              width={212}
              height={500}
            />
          </div>
      </section>
      <section className="grid-x2 grid-x2--break-pro">
        <InfoRemedy/>
        <InfoRemedyRescue/>
      </section>
      {/*<Brands/>*/}
      {/*<Reviews/>*/}
      <FAQ />
    </Layout>
  );
};

export default ShopPage;
