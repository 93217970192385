import "./faq.scss";

import * as React from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import greenCircle from "../../../assets/vectors/green-circle.svg";
import InfoShop from "../InfoShop/InfoShop";

const FAQ: React.FC = () => {
  return (
    <section className={"o-faq a-line-left"}>
      <div className={"is-relative "}>
        <h2 className={"a-faq__title "}>
          Qué ocurre cuando haces clic en comprar remedio de Flores de Bach
        </h2>
        <img
          src={greenCircle}
          className={"a-circle-rotate a-circle-rotate--absolute "}
          alt={"Círculo"}
        />
      </div>
      <div className={"m-faq-text"}>
        <p>
          Llega el momento de crear tu remedio floral personalizado de manera
          sencilla y cómoda.
        </p>
        <p>
          Si seleccionas el{" "}
          <strong>
            <i>preparado floral personalizado,</i>
          </strong>
          te aparecerá el listado de las<i> 38 flores de Bach,</i> del cual
          podrás escoger y seleccionar las flores que necesites, hasta un{" "}
          <b> máximo de 7 flores </b>
          por frasco (mínimo 1). Además tendrás la opción de retirar el
          conservante alcohólico si lo deseas.
        </p>
        <p>
          Si seleccionas el{" "}
          <strong>
            <i> remedio de urgencia personalizado,</i>
          </strong>{" "}
          te aparecerá el listado de <i>las 38 flores de Bach,</i> del cual
          podrás seleccionar las flores que necesites añadir al remedio de
          urgencia, hasta un <b> máximo de 6 flores </b> añadidas por frasco.
        </p>
        <p>
          Una vez realizado el pago, nos encargaremos de elaborar tu preparado
          floral y de enviarlo por correo postal para que lo recibas, en un
          plazo de 24h/48h, a la dirección que nos indiques.
          <br />
          <b>Garantizanda la elaboración por Practitioners </b>
        </p>
        <div className={"m-faq__info"}>
          <p className={"a-faq__subtitle"}>Todos los preparados incluyen:</p>
          <InfoShop />
          <small>
            * Excepto Baleares y Canarias que tienen un suplemento de 10€
          </small>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
