import "./info-shop.scss";

import * as React from "react";

const InfoShop: React.FC = () => {
  return (
    <div className={"m-info-shop "}>
      <ul className={"m-list-check m-info-shop__list"}>
        <li className={"a-info-shop__item"}>Gastos de envío gratis</li>
        <li className={"a-info-shop__item"}>IVA incluido</li>
        <li className={"a-info-shop__item"}>Envío en 24 / 48 horas</li>
      </ul>
    </div>
  );
};

export default InfoShop;
